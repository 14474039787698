<template>
  <div>
    <AppSpinnerBig :loading="loadingOnfido"></AppSpinnerBig>
    <AppCard :class="animationClass">
      <template #header>
        <AppCardHeader>
          <template v-if="mq.current !== 'xs'" #left>
            <AppBackButton name="Go Back" icon="<" analytics-name="onfido-back" @click="back" />
          </template>
          <h1>{{ $t('PageOnfido.Title').value }}</h1>
        </AppCardHeader>
      </template>

      <OnfidoSelectDocument @select="select"></OnfidoSelectDocument>
    </AppCard>

    <Onfido @init="init" @loaded="loaded" @successful="done" @close="close"> </Onfido>
  </div>
</template>

<script>
import { ref, onBeforeMount, onMounted } from '@vue/composition-api'


import { useRouter } from '@galileo/composables/useRouter'
import OnfidoSelectDocument from '@galileo/components/Views/Identification/OnfidoSelectDocument'
import Onfido from '@galileo/components/Views/Identification/Onfido'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'


import {
  useMediaQuery,
  useTransition,
  AppCard,
  AppCardHeader,
  AppBackButton,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'
import { onSuccessfulVerification } from '@galileo/composables/useVerifyUser'


import { useI18nStore, useAuthStore, useSendMoneyStore,  useAnalyticsStore,  } from '@galileo/stores'

export default {
  name: 'SendMoneyOnfido',
  components: {
    AppCard,
    AppCardHeader,
    AppBackButton,
    AppSpinnerBig,
    OnfidoSelectDocument,
    Onfido,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()

    onMounted(() => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.BIOMETRIC_CONSENT_PROVIDED,
        traits: {
          biometricPlatform: sendMoneyStore.verificationPlatform,
          externalSessionId: sendMoneyStore.verificationApplicationId,
        },
      })
    })

    const loadingOnfido = ref(false)
    const animationClass = useTransition('blur', loadingOnfido)

    let onfidoCallback
    const init = (ev) => {
      onfidoCallback = ev
    }

    const loaded = () => {
      loadingOnfido.value = false
    }

    let selectedDocument = ''
    const select = (document) => {
      loadingOnfido.value = true
      selectedDocument = document
      onfidoCallback.selectDocument(document)
    }

    const done = async () => {
      onSuccessfulVerification(router.replace)
    }

    const back = () => {
      // a bit complicated since eventually Onfido cluttered the history but didn't clean it up
      router.goBackSteps(1)
    }

    const close = () => {
      router.replace({ name: STEPS.SEND_MONEY_SUMMARY })
    }

    onBeforeMount(async () => {
      sendMoneyStore.setActiveStep('/send-money/summary/verification')
    })

    return {
      mq: useMediaQuery(),
      loadingOnfido,
      animationClass,
      init,
      loaded,
      select,
      done,
      back,
      close,
      $t,
    }
  },
}
</script>

<style scoped></style>
