<template>
  <div>
    <div title="Processing" class="overlapping" v-if="veriffStarting && !veriffPollingStarted">
      <AppSpinnerBig class="onfido-spinner m-0" :loading="veriffStarting" :inline="true" />
    </div>

    <!-- Polling in progress  -->
    <InformationAppModal
      :shouldDisplayCloseButton="false"
      @close="() => null"
      :value="veriffPollingStarted"
      class="polling-modal"
      no-icon
    >
      <div title="Processing" class="overlapping">
        <IconHourglassGalileo />
        <AppSpinnerBig class="onfido-spinner m-0" :loading="true" :inline="true" />
      </div>
      <h1 class="modal-title">
        {{ $t('PageOnfido.VerifyingTitle').value }}
      </h1>
      <p>
        {{ $t('PageOnfido.VerifyingText').value }}
      </p>
      <template #footer>
        <div></div>
      </template>
    </InformationAppModal>

    <VerificationOutcomeModal v-if="verificationOutcome" :outcome="verificationOutcome" />
  </div>
</template>

<script>
import { ref, onBeforeMount, onMounted } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import { IconHourglassGalileo } from '@oen.web.vue2/icons'
import { checkIfUserVerified } from '@galileo/composables/useVerifyUser'
import { VERIFICATION_OUTCOMES } from '@galileo/constants/Verification.const'

import { AppCard, AppCardHeader, AppBackButton, AppSpinnerBig, AppButton } from '@oen.web.vue2/ui'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal.vue'
import VerificationOutcomeModal from '@galileo/components/VerificationOutcomeModal/VerificationOutcomeModal.vue'
import { useSendMoneyStore, useAnalyticsStore, useAppStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'SendMoneyOnfido',
  components: {
    AppCard,
    AppCardHeader,
    AppBackButton,
    AppSpinnerBig,
    InformationAppModal,
    IconHourglassGalileo,
    AppButton,
    VerificationOutcomeModal,
    VERIFICATION_OUTCOMES,
  },
  setup() {
    const i18nStore = useI18nStore()
    const { $t } = i18nStore
    const router = useRouter()
    const isPolling = ref(false)
    const setIsPolling = (newValue) => (isPolling.value = newValue)
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()

    const token = sendMoneyStore.verificationToken

    const verificationOutcome = ref(null)
    const checkAlreadyStarted = ref(false)
    const veriffPollingStarted = ref(false)

    const setVerificationOutcome = async (status) => {
      if (status === VERIFICATION_OUTCOMES.EDDRequired) {
        const nextPath = await sendMoneyStore.processOrder()

        sendMoneyStore.setFilledAdditionalFields(null)
        router.replace(nextPath)
      } else {
        verificationOutcome.value = VERIFICATION_OUTCOMES[status]
      }
    }

    const loadingContactUs = ref(false)
    const veriffInstance = ref(null)

    const startVeriff = async () => {
      // Ensure there are no other instances of veriff activated
      if (veriffInstance.value) veriffInstance.value = null

      const veriffBaseUrl = sendMoneyStore.veriffBaseUrl

      veriffInstance.value = createVeriffFrame({
        url: `${veriffBaseUrl}${token}`,
        lang: i18nStore.languageTagLanguage(i18nStore.i18n.locale),
        onEvent: async (msg) => {
          switch (msg) {
            case MESSAGES.CANCELED:
              onCancelHandler()
              break
            case MESSAGES.FINISHED:
              analyticsStore.track({
                event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_SUBMITTED,
                traits: {
                  biometricPlatform: sendMoneyStore.verificationPlatform,
                  location: 'sendMoney',
                },
              })
              veriffPollingStarted.value = true
              await checkIfUserVerified(setIsPolling, checkAlreadyStarted, setVerificationOutcome)
              break
            case MESSAGES.RELOAD_REQUEST:
            case MESSAGES.STARTED:
              veriffStarting.value = false
              break
            default:
              break
          }
        },
      })
    }

    const veriffStarting = ref(false)

    onMounted(async () => {
      veriffStarting.value = true
      await startVeriff()
    })

    const onCancelHandler = () => {
      // __SEGMENT__EVENT
      analyticsStore.track({
        event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_EXITED,
        traits: {
          location: 'sendMoney',
          biometricPlatform: sendMoneyStore.verificationPlatform,
        },
      })
      router.replace({ name: STEPS.SEND_MONEY_SUMMARY })
    }

    onBeforeMount(async () => {
      sendMoneyStore.setActiveStep('/send-money/summary/verification')
    })

    const contactUs = () => {
      useAppStore().openHelpDesk({ loadingRef: loadingContactUs })
    }

    return {
      veriffStarting,
      veriffPollingStarted,
      close,
      $t,
      verificationOutcome,
      loadingContactUs,
      contactUs,
      checkAlreadyStarted,
      isPolling,
      VERIFICATION_OUTCOMES,
    }
  },
}
</script>

<style scoped>
.overlapping svg {
  position: absolute;
}
::v-deep .overlapping .loading-spinner .loading-spinner-container {
  margin: 10px;
}
::v-deep .overlapping .loading-spinner .loading-spinner-container svg {
  height: 80px;
  width: 80px;
}

::v-deep .polling-modal .card-content-block {
  margin: 0px;
}
::v-deep .polling-modal .card-header {
  display: none;
}

.overlapping {
  display: flex;
  justify-content: center;
  align-items: center;
  position: rleative;
  margin: 30px 0px 0px 0px;
}
</style>
